import React from "react"

//Gatsby imports
import { graphql } from "gatsby"

//React components imports
import Layout from "../../components/Layout"
import Breadcrumbs from "../../components/Breadcrumbs"
import Collections from "../../components/Collections"

//Styled components imports
import Title from "../../shared-styled-components/Title"

const SubcategoriesPage = ({ data, pageContext, location }) => {
  return (
    <Layout subtitle={"pageContext.title"}>
      <Breadcrumbs
        data={{
          path: "/produkty" + location.pathname.replace("/en", ""),
          breadEnd: pageContext.title,
        }}
      />
      <Title>{pageContext.title}</Title>
      <Collections data={data.allShopifyCollection.nodes} />
    </Layout>
  )
}

export default SubcategoriesPage

export const query = graphql`
  query SubCategoriesQuery($regex: String) {
    allShopifyCollection(filter: { title: { regex: $regex } }) {
      nodes {
        id
        handle
        title
        image {
          gatsbyImageData(width: 550)
        }
      }
    }
  }
`
